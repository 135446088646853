import {useCallback, useEffect, useMemo, useState} from 'react'
import {DropdownOptionProps} from 'wix-ui-tpa/cssVars'
import {useBi, useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {TimeSlot} from '@wix/ambassador-table-reservations-v1-time-slot/types'

import {useReservationsStorage} from '../storage'
import {filterDate} from '../../../utils/date'
import {getBusinessScheduleFromReservationLocation} from '../../../utils/businessSchedule'
import {TimeOption} from '../../../utils/timeOptions'
import {useSelectedDateAndTime} from '../../../utils/useSelectedDateAndTime'
import {
  getInitialPartySizeFromOptions,
  getPartySizeOptionsForReservationLocation,
} from '../../../utils/partySize'
import {EDITOR_MOCK_RESERVATION_LOCATION_ID} from '../../../editor/editorMocks/getReservationLocationsMock'
import {FieldName, PageType, getLogger} from '../../../utils/getLogger'
import {
  getEnabledReservationLocations,
  getInitialReservationLocation,
  getReservationLocationById,
  getReservationLocationsOptions,
} from '../../../utils/reservationLocation'
import {scrollTo} from '../../../utils/scroll'
import {RequestStatus} from '../../../utils/wrapRequest'
import {reservationsFormDataHooks} from '../components/Form/constants'
import {ITimeSlotsByDays} from '../constants'
import {timeSlotsService} from '../../../services/timeSlotsService'
import {useElementWidth} from '../../../utils/useElementWidth'
import {reserveButtonDataHooks} from '../components/ReserveButton/constants'
import {useGetLayoutSize} from '../../../components-shared/LayoutSizeProvider'
import {useApproveTextView} from '../../../utils/useApproveTextView'

import {ReservationsPageType} from './constants'

export const useHooks = () => {
  const {t} = useTranslation()
  const bi = useBi()
  const logger = getLogger(bi)
  const {width: submitButtonContentWidth, readAndSetWidth: readAndSetSubmitButtonContentWidth} =
    useElementWidth<HTMLSpanElement>(reserveButtonDataHooks.button())

  const {layoutSize, containerWidth} = useGetLayoutSize()

  const {
    regionalSettings,
    getReservationLocationsStatus,
    getTimeSlotsStatus,
    reservationLocations,
    timeSlots,
    selectedReservationLocationId,
    selectedPartySize,
    selectedDate: preselectedDate,
    getTimeSlots,
    prefetchCurrentMember,
    handleReservationData,
    handleReservationDataStatus,
    metaSiteId,
    queryValidation,
    approvalTextEditorState,
  } = useReservationsStorage()
  const isQueryValid =
    queryValidation.startDate && queryValidation.partySize && queryValidation.reservationLocationId
  const {isEditor, isPreview, isEditorX} = useEnvironment()

  const isNoReservationLocations = !reservationLocations.length

  const enabledReservationLocations = getEnabledReservationLocations(reservationLocations)

  // reservations unavailable
  const [shouldShowReservationsUnavailable, setShouldShowReservationsUnavailable] = useState(false)

  const handleHideReservationsUnavailable = useCallback(() => {
    setShouldShowReservationsUnavailable(false)
  }, [])

  // reservation location
  const [selectedReservationLocation, setSelectedReservationLocation] = useState(
    getInitialReservationLocation(reservationLocations, selectedReservationLocationId),
  )

  const reservationLocationsOptions: DropdownOptionProps[] = getReservationLocationsOptions(
    enabledReservationLocations,
    t,
  )

  // party size
  const partySizeOptions = useMemo(
    () => getPartySizeOptionsForReservationLocation(t, selectedReservationLocation),
    [selectedReservationLocation],
  )

  const [partySize, setPartySize] = useState(
    selectedPartySize ?? getInitialPartySizeFromOptions(partySizeOptions),
  )

  // manual approval
  const [isManualApproval, setIsManualApproval] = useState(false)

  useEffect(() => {
    const manualApprovalConfig =
      selectedReservationLocation?.configuration?.onlineReservations?.manualApproval

    const shouldBeManualApprove =
      !!manualApprovalConfig?.enabled && partySize >= manualApprovalConfig.partySizeThreshold!

    setIsManualApproval(shouldBeManualApprove)
  }, [partySize, selectedReservationLocation])

  // businessSchedule
  const businessSchedule = useMemo(
    () => getBusinessScheduleFromReservationLocation(selectedReservationLocation),
    [selectedReservationLocation],
  )

  const preselectedDateForPreselectedReservationLocation =
    selectedReservationLocation?.id === selectedReservationLocationId ? preselectedDate : undefined
  const isPreselectedDateValid =
    selectedReservationLocation?.id === selectedReservationLocationId
      ? queryValidation.startDate
      : undefined

  // date & time
  const {selectedDate, selectedTime, timeOptions, onSelectedDateChange, onSelectedTimeChange} =
    useSelectedDateAndTime({
      businessSchedule,
      preselectedDate: preselectedDateForPreselectedReservationLocation,
      regionalSettings,
      isPreselectedDateValid,
      timeZone: selectedReservationLocation?.location?.timeZone,
    })

  // time slot
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<Date>()

  const [showSelectTimeSlotError, setShowSelectTimeSlotError] = useState(false)

  // BI
  const clickOnReservationsAttributesBiParams = useMemo(
    () => ({
      isPreview,
      isAddOn: false,
      isMultiLocation: !!enabledReservationLocations.length,
      locationId: selectedReservationLocation?.location?.id,
      reservationLocationId: selectedReservationLocation?.id,
    }),
    [enabledReservationLocations.length, isPreview, selectedReservationLocation],
  )

  const handleFilterDate = useCallback(
    (date: Date) =>
      filterDate({
        date,
        businessSchedule,
        timeZone: selectedReservationLocation?.location?.timeZone,
        regionalFormat: regionalSettings,
      }),
    [businessSchedule, selectedReservationLocation?.location?.timeZone, regionalSettings],
  )

  const handleLocationChange = (option: DropdownOptionProps) => {
    const newSelectedReservationLocation =
      getReservationLocationById(option.id!, enabledReservationLocations) ??
      enabledReservationLocations[0]

    setSelectedReservationLocation(newSelectedReservationLocation)

    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      locationId: newSelectedReservationLocation?.location?.id,
      reservationLocationId: newSelectedReservationLocation?.id,
      fieldName: FieldName.location,
    })

    if (
      partySize <
        newSelectedReservationLocation?.configuration?.onlineReservations?.partiesSize?.min! ||
      partySize >
        newSelectedReservationLocation?.configuration?.onlineReservations?.partiesSize?.max!
    ) {
      setPartySize(
        newSelectedReservationLocation?.configuration?.onlineReservations?.partiesSize?.min!,
      )
    }
  }

  const handlePartySizeChange = (option: DropdownOptionProps) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.partySize,
    })

    setPartySize(Number(option.id))
  }

  const handleDateChange = (newDate: Date) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.date,
    })

    setSelectedTimeSlot(undefined)
    onSelectedDateChange(newDate)
  }

  const handleTimeChange = (newSelectedTime: TimeOption) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.time,
    })

    setSelectedTimeSlot(undefined)
    onSelectedTimeChange(newSelectedTime)
  }

  const handleTimeSlotChange = (item: TimeSlot) => {
    setSelectedTimeSlot(item.startDate)
    setShowSelectTimeSlotError(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    readAndSetSubmitButtonContentWidth()

    if (isInitialDataInProgress || handleReservationDataStatus === RequestStatus.LOADING) {
      return
    }

    if (!selectedTimeSlot) {
      setShowSelectTimeSlotError(true)
      return
    }

    if (!selectedReservationLocation?.id) {
      return
    }

    if (!enabledReservationLocations.length) {
      setShouldShowReservationsUnavailable(true)
      return
    }

    logger.clickOnReserveNow({
      locationId: selectedReservationLocation.location?.id!,
      reservationLocationId: selectedReservationLocation.id,
      isPreview,
      partySize,
      requestedDate: selectedTimeSlot,
    })

    handleReservationData({
      details: {
        reservationLocationId: selectedReservationLocation.id,
        partySize,
        startDate: selectedTimeSlot,
      },
      timeZone: selectedReservationLocation?.location?.timeZone!,
      isManualApproval,
    })
  }

  const handleSearchAgainClick = () => {
    loadTimeSlots()
    scrollTo(reservationsFormDataHooks.root())
  }

  const loadTimeSlots = () => {
    if (isLargeParty || !selectedReservationLocation?.id) {
      return
    }

    getTimeSlots(
      {
        date: selectedDate,
        partySize,
        reservationLocationId: selectedReservationLocation.id,
      },
      selectedReservationLocation?.location?.timeZone,
    )
  }

  const handleErrorClick = () => {
    window.location.href = window.location.href.split('?')[0]
  }

  const {shouldShowAutoApproveText} = useApproveTextView(
    enabledReservationLocations,
    approvalTextEditorState,
  )

  const isInitialDataInProgress =
    getReservationLocationsStatus !== RequestStatus.RESOLVED ||
    getTimeSlotsStatus !== RequestStatus.RESOLVED

  const isLargeParty =
    selectedReservationLocation?.configuration?.onlineReservations?.partiesSize?.max &&
    partySize > selectedReservationLocation.configuration.onlineReservations.partiesSize.max

  const phoneNumber = selectedReservationLocation?.location?.phone
    ? selectedReservationLocation.location.phone
    : undefined

  const shouldShowNoLocationsEditorWarning =
    (isEditor || isPreview) &&
    enabledReservationLocations[0].id === EDITOR_MOCK_RESERVATION_LOCATION_ID

  const reservationsPageType = getReservationsPageType(timeSlots)

  const shouldShowSearchAgainButton =
    reservationsPageType === ReservationsPageType.EXTENDED || isEditor

  useEffect(() => {
    logger.isLoaded({
      isAddOn: false,
      isPreview,
      pageType: PageType.page,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadTimeSlots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, partySize])

  // should trigger time slots load for Editor X or Wix Studio
  // (Editor reinitialize controller but doesn't reinitialize the component)
  useEffect(() => {
    const shouldTriggerTimeSlotsLoad =
      isEditorX && !Object.keys(timeSlots).length && getTimeSlotsStatus === RequestStatus.DEFAULT

    if (shouldTriggerTimeSlotsLoad) {
      loadTimeSlots()
    }
  }, [isEditorX, timeSlots, getTimeSlotsStatus, loadTimeSlots])

  useEffect(() => {
    prefetchCurrentMember()
  }, [prefetchCurrentMember])

  return {
    t,
    submitButtonContentWidth,
    isPreview,
    layoutSize,
    containerWidth,
    phoneNumber,
    regionalSettings,
    isLargeParty,
    isInitialDataInProgress,
    getTimeSlotsStatus,
    handleReservationDataStatus,
    reservationsPageType,
    partySizeOptions,
    timeOptions,
    partySize,
    selectedDate,
    selectedTime,
    selectedTimeSlot,
    timeSlots,
    handlePartySizeChange,
    handleDateChange,
    handleTimeChange,
    handleTimeSlotChange,
    filterDate: handleFilterDate,
    handleSubmit,
    reservationLocationsOptions,
    selectedReservationLocation,
    handleLocationChange,
    shouldShowNoLocationsEditorWarning,
    metaSiteId,
    showSelectTimeSlotError,
    handleSearchAgainClick,
    isQueryValid,
    handleErrorClick,
    shouldShowSearchAgainButton,
    shouldShowReservationsUnavailable,
    handleHideReservationsUnavailable,
    isNoReservationLocations,
    shouldShowAutoApproveText,
  }
}

const getReservationsPageType = (timeSlotsByDays: ITimeSlotsByDays): ReservationsPageType => {
  const daysCount = Object.keys(timeSlotsByDays).length

  if (daysCount === 0) {
    return ReservationsPageType.EMPTY
  }

  if (daysCount === 1) {
    // TODO: change it isSameDate when resolve issue with sameDaySlots
    const isExtended =
      Object.values(timeSlotsByDays)[0].length === timeSlotsService.EXTENDED_TYPE_SLOTS_COUNT
    return isExtended ? ReservationsPageType.EXTENDED : ReservationsPageType.DEFAULT
  }

  return ReservationsPageType.EXTENDED
}
