import React from 'react'
import {Button} from 'wix-ui-tpa/cssVars'
import {useTranslation} from '@wix/yoshi-flow-editor'

import {LayoutSize} from '../../../../components-shared/LayoutSizeProvider/types'
import {Modal} from '../../../../components-shared/Modal'
import {NotAcceptingBanner} from '../../../../components-shared/NotAcceptingBanner'

import {classes} from './styles.st.css'

type NotAcceptingModalProps = {
  isOpen: boolean
  onClose: () => void
  layoutSize: LayoutSize
  bannerClassName?: string
}

export const NotAcceptingModal: React.FC<NotAcceptingModalProps> = ({
  isOpen,
  onClose,
  layoutSize,
  bannerClassName,
}) => {
  const {t} = useTranslation()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <NotAcceptingBanner layoutSize={layoutSize} className={bannerClassName} />
      <Button autoFocus={false} className={classes.notAcceptingModalButton} onClick={onClose}>
        {t('uou-reservations.reservations.not-accepting-modal-close-button')}
      </Button>
    </Modal>
  )
}
