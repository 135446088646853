import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {
  Reservation,
  Details,
  Source,
  Reservee,
} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {
  getReservation as getReservationService,
  createHeldReservation,
  createReservation as createReservationService,
  reserveReservation as reserveReservationService,
  cancelReservation as cancelReservationService,
  deleteReservation as deleteReservationService,
} from '@wix/ambassador-table-reservations-v1-reservation/http'

import {
  getDataFromMemoryCache,
  setDataToMemoryCache,
  removeFromMemoryCache,
} from '../utils/dataCapsule'

const CACHE_KEY = 'tr-reserved-reservation'

const getReservation = async (
  flowAPI: ControllerFlowAPI,
  id: string,
): Promise<Reservation | undefined> => {
  const reservationFromCache = getDataFromMemoryCache<Reservation>(flowAPI, CACHE_KEY)

  if (reservationFromCache) {
    removeFromMemoryCache(flowAPI, CACHE_KEY)
    deserializeReservationDates(reservationFromCache)

    return reservationFromCache
  } else {
    const req = getReservationService({reservationId: id})
    const res = await flowAPI.httpClient.request(req)

    return res?.data?.reservation
  }
}

const holdReservation = async (
  flowAPI: ControllerFlowAPI,
  reservationDetails: Details,
): Promise<Reservation | undefined> => {
  const req = createHeldReservation({reservationDetails})
  const res = await flowAPI.httpClient.request(req)
  const createdReservation = res?.data?.reservation

  if (createdReservation) {
    setDataToMemoryCache(flowAPI, CACHE_KEY, createdReservation)
  }

  return createdReservation
}

const reserveReservation = async (
  flowAPI: ControllerFlowAPI,
  id: string,
  reservee: Reservee,
  revision: string,
): Promise<Reservation | undefined> => {
  const req = reserveReservationService({
    reservationId: id,
    reservee,
    revision,
  })

  const res = await flowAPI.httpClient.request(req)

  const reservation = res?.data?.reservation

  if (reservation) {
    setDataToMemoryCache(flowAPI, CACHE_KEY, reservation)
  }

  return reservation
}

const cancelReservation = async (
  flowAPI: ControllerFlowAPI,
  param: {reservationId: string; revision: string; phone?: string},
): Promise<Reservation | undefined> => {
  const req = cancelReservationService({
    reservationId: param.reservationId,
    revision: param.revision,
    phone: param.phone,
  })
  const res = await flowAPI.httpClient.request(req)

  return res?.data?.reservation
}

const deleteReservation = async (
  flowAPI: ControllerFlowAPI,
  param: {reservationId: string; revision: string},
) => {
  const req = deleteReservationService({
    reservationId: param.reservationId,
    revision: param.revision,
  })

  await flowAPI.httpClient.request(req)
}

const createReservation = async (flowAPI: ControllerFlowAPI, reservation: Reservation) => {
  const req = createReservationService({
    reservation: {
      ...reservation,
      source: Source.ONLINE,
    },
  })

  const res = await flowAPI.httpClient.request(req)
  const createdReservation = res?.data?.reservation

  if (createdReservation) {
    setDataToMemoryCache(flowAPI, CACHE_KEY, createdReservation)
  }

  return createdReservation
}

const deserializeReservationDates = (reservationFromCache: Reservation) => {
  if (reservationFromCache?.details?.startDate) {
    reservationFromCache.details.startDate = new Date(reservationFromCache.details.startDate)
  }

  if (reservationFromCache?.details?.endDate) {
    reservationFromCache.details.endDate = new Date(reservationFromCache.details.endDate)
  }

  if (reservationFromCache?.createdDate) {
    reservationFromCache.createdDate = new Date(reservationFromCache.createdDate)
  }

  if (reservationFromCache?.updatedDate) {
    reservationFromCache.updatedDate = new Date(reservationFromCache.updatedDate)
  }
}

export const reservationsService = {
  getReservation,
  holdReservation,
  reserveReservation,
  cancelReservation,
  deleteReservation,
  createReservation,
}
