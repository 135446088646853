import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {GetTimeSlotsRequest} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import {Member} from '@wix/ambassador-members-v1-member/types'

import {reservationLocationsService} from '../../services/reservationLocationsService'
import {noop} from '../../utils/helpers'
import {getRegionalSettings} from '../../utils/flowAPI'
import {RequestStatus, wrapRequest} from '../../utils/wrapRequest'
import {createStorageProvider} from '../../utils/storageContext'
import {getReservationLocationsMock} from '../../editor/editorMocks/getReservationLocationsMock'
import {getTimeSlotsMock} from '../../editor/editorMocks/getTimeSlotsMock'
import {handleReservationDataMock} from '../../editor/editorMocks/getReservationMock'
import {timeSlotsService} from '../../services/timeSlotsService'
import {getReservationDataQueryParamsValidation} from '../../utils/queryParams'
import {getCurrentMember} from '../../services/memberService'
import {ApprovalTextEditorState} from '../../utils/constants'

import {handleReservationData} from './actions/handleReservationData'
import {ITimeSlotsByDays} from './constants'

interface ReservationsData {
  regionalSettings?: string
  getReservationLocationsStatus: RequestStatus
  getTimeSlotsStatus: RequestStatus
  handleReservationDataStatus: RequestStatus
  metaSiteId?: string
  reservationLocations: ReservationLocation[]
  timeSlots: ITimeSlotsByDays
  selectedReservationLocationId?: string
  selectedPartySize?: number
  selectedDate?: string
  queryValidation: {
    startDate: boolean
    partySize: boolean
    reservationLocationId: boolean
  }
  fitToContentHeight: boolean
  currentMember?: Member
  prefetchCurrentMemberStatus: RequestStatus
  approvalTextEditorState: ApprovalTextEditorState
}

interface ReservationsActions {
  getReservationLocations: () => Promise<ReservationLocation[] | undefined>
  getTimeSlots: (
    params: GetTimeSlotsRequest,
    tz?: string | null,
  ) => Promise<ITimeSlotsByDays | undefined>
  handleReservationData: ReturnType<typeof handleReservationData>
  prefetchCurrentMember: () => Promise<Member | null | undefined>
}

export interface ReservationsStorage extends ReservationsData, ReservationsActions {}

const defaultReservationsStorage: ReservationsStorage = {
  getReservationLocationsStatus: RequestStatus.DEFAULT,
  getTimeSlotsStatus: RequestStatus.DEFAULT,
  handleReservationDataStatus: RequestStatus.DEFAULT,
  prefetchCurrentMemberStatus: RequestStatus.DEFAULT,
  reservationLocations: [],
  timeSlots: {},
  getReservationLocations: noop,
  getTimeSlots: noop,
  handleReservationData: noop,
  queryValidation: {
    startDate: true,
    partySize: true,
    reservationLocationId: true,
  },
  fitToContentHeight: true,
  prefetchCurrentMember: noop,
  approvalTextEditorState: ApprovalTextEditorState.unknown,
}

const {withStorageProvider, useStorage} = createStorageProvider(defaultReservationsStorage)

const initReservationsStorage = (
  flowAPI: ControllerFlowAPI,
  controllerConfig: IWidgetControllerConfig,
): ReservationsStorage => {
  const query = flowAPI.controllerConfig?.wixCodeApi?.location?.query || {}
  const queryValidation = getReservationDataQueryParamsValidation(query, true)

  return {
    ...defaultReservationsStorage,
    queryValidation,
    metaSiteId: controllerConfig?.platformAPIs?.bi?.metaSiteId,
    regionalSettings: getRegionalSettings(flowAPI),
    selectedReservationLocationId: query?.reservationLocationId,
    selectedPartySize:
      query?.reservationLocationId && query?.partySize ? Number(query.partySize) : undefined,
    selectedDate: query?.startDate,
    getReservationLocations: wrapRequest(
      flowAPI,
      reservationLocationsService.getReservationLocations,
      'reservationLocations',
      'getReservationLocationsStatus',
    ),
    getTimeSlots: wrapRequest(
      flowAPI,
      timeSlotsService.getTimeSlots,
      'timeSlots',
      'getTimeSlotsStatus',
    ),
    handleReservationData: handleReservationData(flowAPI),
    prefetchCurrentMember: wrapRequest(
      flowAPI,
      getCurrentMember,
      'currentMember',
      'prefetchCurrentMemberStatus',
    ),
  }
}

const mockReservationsStorage = (
  flowAPI: ControllerFlowAPI,
  controllerConfig: IWidgetControllerConfig,
): ReservationsStorage => {
  const initiatedStorage = initReservationsStorage(flowAPI, controllerConfig)

  return {
    ...initiatedStorage,
    getReservationLocations: wrapRequest(
      flowAPI,
      getReservationLocationsMock,
      'reservationLocations',
      'getReservationLocationsStatus',
    ),
    getTimeSlots: wrapRequest(flowAPI, getTimeSlotsMock, 'timeSlots', 'getTimeSlotsStatus'),
    handleReservationData: handleReservationDataMock(flowAPI),
  }
}

export {
  initReservationsStorage,
  mockReservationsStorage,
  withStorageProvider as withReservationsStorageProvider,
  useStorage as useReservationsStorage,
}
