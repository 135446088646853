import {
  DatePickerInput,
  Dropdown,
  DropdownOptionProps,
  DropdownTheme,
  TextFieldTheme,
} from 'wix-ui-tpa/cssVars'
import React, {FC, useRef} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {useSettings} from '@wix/tpa-settings/react'
import {DatePickerInputFirstDayOfWeek} from 'wix-ui-tpa'

import {TimeOption} from '../../../../utils/timeOptions'
import {settingsParams} from '../../settingsParams'
import {FormLayout} from '../../../../types/createStylesParams'
import {getFirstDayOfWeek, parseLocaleFromBM} from '../../../../utils/locale'
import {LayoutSize} from '../../../../components-shared/LayoutSizeProvider/types'

import {classes, st} from './Form.st.css'
import {reservationsFormDataHooks} from './constants'

type DefaultOptions = DropdownOptionProps[]
type DefaultOnChange = (option: DropdownOptionProps) => void

interface Props {
  layoutSize: LayoutSize
  containerWidth: number
  regionalSettings?: string
  location: {
    value?: string
    options: DefaultOptions
    onChange: DefaultOnChange
  }
  partySize: {
    value: string
    options: DefaultOptions
    onChange: DefaultOnChange
  }
  date: {
    value: Date
    filterDate: (date: Date) => boolean
    onChange: (date: Date) => void
  }
  time: {
    value?: string
    options: TimeOption[]
    onChange: (option: TimeOption) => void
  }
}

export const Form: FC<Props> = ({
  regionalSettings,
  location,
  partySize,
  date,
  time,
  layoutSize,
  containerWidth,
}) => {
  const {t} = useTranslation()
  const settings = useSettings()
  const {isMobile, isSSR} = useEnvironment()
  const containerRef = useRef<HTMLDivElement | null>()

  const locale = parseLocaleFromBM(regionalSettings)

  const weekFirstDay = getFirstDayOfWeek(locale) as DatePickerInputFirstDayOfWeek

  // prevents layout flickering after initial render
  if (containerWidth === 0) {
    return null
  }

  const textFieldTheme = settings.get(settingsParams.textFieldTheme)
  const formLayout =
    layoutSize === LayoutSize.XS || layoutSize === LayoutSize.S
      ? FormLayout.Packed
      : settings.get(settingsParams.formLayout)

  const isFormLayoutPacked = formLayout === FormLayout.Packed
  const isTextFieldThemeBox = textFieldTheme === TextFieldTheme.Box

  const formLayoutClass = isFormLayoutPacked ? classes.packed : classes.strip
  const textFieldThemeClass = isTextFieldThemeBox ? classes.box : classes.line
  const dropDownClass = isTextFieldThemeBox ? classes.box : classes.line
  const datePickerClass = isTextFieldThemeBox ? classes.box : classes.line

  const dropdownTheme = isTextFieldThemeBox ? DropdownTheme.Box : DropdownTheme.Line
  const datePickerTheme = isTextFieldThemeBox ? TextFieldTheme.Box : TextFieldTheme.Line

  const areLocationsExist = location.options.length > 1
  const withLocationDropdownClass = areLocationsExist ? classes.withLocation : undefined

  return (
    <div
      ref={(ref) => (containerRef.current = ref)}
      className={st(
        classes.root,
        {[layoutSize]: true},
        formLayoutClass,
        textFieldThemeClass,
        withLocationDropdownClass,
      )}
      data-hook={reservationsFormDataHooks.root()}
    >
      {areLocationsExist && (
        <div>
          <Dropdown
            upgrade
            label={t('uou-reservations.reservations.location')}
            className={st(classes.dropdown, dropDownClass)}
            initialSelectedId={location.value}
            options={location.options}
            onChange={location.onChange}
            theme={dropdownTheme}
            data-hook={reservationsFormDataHooks.location()}
          />
        </div>
      )}

      <div>
        <Dropdown
          upgrade
          label={t('uou-reservations.reservations.party')}
          className={st(classes.dropdown, dropDownClass)}
          initialSelectedId={partySize.value.toString()}
          options={partySize.options}
          onChange={partySize.onChange}
          theme={dropdownTheme}
          data-hook={reservationsFormDataHooks.partySize()}
        />
      </div>

      <div>
        <DatePickerInput
          placeholderText=""
          label={t('uou-reservations.reservations.date')}
          locale={parseLocaleFromBM(regionalSettings)}
          firstDayOfWeek={weekFirstDay}
          className={st(classes.datePicker, datePickerClass)}
          inputWidth="100%"
          excludePastDates
          value={date.value}
          filterDate={date.filterDate}
          onChange={date.onChange}
          inputTheme={datePickerTheme}
          popoverAppendTo={
            isMobile && !isFormLayoutPacked ? containerRef.current ?? 'parent' : 'parent'
          }
          data-hook={reservationsFormDataHooks.date()}
          popoverPlacement={isMobile ? 'bottom' : 'bottom-start'}
          removeClearButton={true}
          zIndex={9999}
        />
      </div>

      <div>
        <Dropdown
          upgrade
          key={time.value}
          label={t('uou-reservations.reservations.time')}
          className={st(classes.dropdown, dropDownClass)}
          // server renders UTC time
          initialSelectedId={isSSR ? undefined : time.value}
          options={time.options}
          onChange={time.onChange}
          theme={dropdownTheme}
          data-hook={reservationsFormDataHooks.time()}
        />
      </div>
    </div>
  )
}
